import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#FFFFFF',
  };
});

export const apertureDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#4C9AFF',
        dark: '#0052CC',
        light: '#2684FF',
      },
      secondary: {
        main: '#FFAB00',
        light: '#FF5630',
        dark: '#FF8B00',
      },
      grey: {
        50: '#424242',
        100: '#616161',
        200: '#757575',
        300: '#9E9E9E',
        400: '#BDBDBD',
        500: '#E0E0E0',
        600: '#EEEEEE',
        700: '#F5F5F5',
        800: '#FAFAFA',
        900: '#FFFFFF',
      },
      error: {
        main: '#DE350B',
        light: '#FF8F73',
        dark: '#A30000',
      },
      warning: {
        main: '#FFAB00',
        light: '#FFE380',
        dark: '#FF8B00',
      },
      success: {
        main: '#36B37E',
        light: '#79F2C0',
        dark: '#006644',
      },
      info: {
        main: '#0065FF',
        light: '#4C9AFF',
        dark: '#0747A6',
      },
      navigation: {
        ...palettes.dark.navigation,
        // background: '#26385A',
        background: "#000000",
        color: '#FFFFFF',
        indicator: '#2684FF',
        navItem: {
          hoverBackground: 'rgba(255,255,255,0.1)',
        },
      },
      text: {
        primary: '#FFFFFF',
      },
      background: {
        default: '#121212',
        paper: '#1E1E1E',
      },
    },
  }),
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: pageThemesFontColorOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundImage: 'unset',
          boxShadow: 'unset',
          paddingBottom: theme.spacing(1),
        }),
        title: ({ theme }) => ({
          color: theme.page.fontColor,
          fontWeight: 900,
        }),
        subtitle: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: '1px solid #D5D5D5',
            boxShadow: 'none',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
          },
        },
      },
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardInfo: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.light,
          '& $icon': {
            color: theme.palette.grey[700],
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&[aria-expanded=true]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
          '&[aria-expanded=true] path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 3,
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.primary.dark,
          margin: 4,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#FFFFFF',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
});
